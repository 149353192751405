import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { isMobile } from "react-device-detect"

const OutlinedGreyTxt = styled.span`
  color: ${props => props.color};
  display: inline-block;
  letter-spacing: 4px; /* 文字間 */
  text-shadow: 1px 1px 0px grey;
`

const OutlineBlack = styled.span`
  display: inline-block;
  color: #000000; /* 文字の色 */
  letter-spacing: 4px; /* 文字間 */
  text-shadow: 2px 2px 0px white, -2px -2px 0px white, 4px 4px 0px darkorange;
`

const OutlineYellow = styled.span`
  display: inline-block;
  color: #ffff1a; /* 文字の色 */
  letter-spacing: 4px; /* 文字間 */
  text-shadow: 4px 4px 0px darkorange;
`

const OutlinedBlue = styled.span`
  display: inline-block;
  color: slateblue; /* 文字の色 */
  letter-spacing: 4px; /* 文字間 */
  text-shadow: 4px 4px 0px darkorange;
`

const useStyles = makeStyles(theme => ({
  whiteContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "2rem",
  },
  blackContainer: {
    position: "relative",
    backgroundColor: "#161616",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  labelPaper: {
    position: "relative",
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "2rem",
  },
  labelPaperTxtWrapper1: {
    position: "absolute",
    top: "22%",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    width: "90%",
  },
  labelPaperTxtWrapper2: {
    position: "absolute",
    top: "18%",
    left: "48%",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    width: "90%",
  },
  labelPaperTxt1: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontWeight: 700,
    fontSize: "1.4rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
  },
  labelPaperTxt2: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontWeight: 700,
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
  },
  titleContainer1: {
    background: "linear-gradient(to bottom, white, cyan, white)",
    padding: "1rem",
  },
  titleContainer2: {
    background: "#161616",
    padding: "1rem",
    marginBottom: "2rem",
  },
  hosokuTxt: {
    fontSize: "1.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
  titleInlineLeft1: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontSize: "2.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
    fontWeight: 700,
    textDecoration: "underline",
  },
  titleInlineLeft2: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    color: "red",
    fontSize: "2.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
    fontWeight: 700,
    textDecoration: "underline",
  },
  titleInlineLeft3: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    color: "white",
    fontSize: "2.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
    fontWeight: 700,
    textDecoration: "underline",
  },
  titleInlineRight1: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontSize: "2.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
    fontWeight: 700,
    textDecoration: "underline",
  },
  titleInlineRight2: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontSize: "2.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.8rem",
    },
    fontWeight: 700,
    color: "cyan",
    textDecoration: "underline",
  },
  pointTitle: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    color: "blue",
    marginBottom: "1.2rem",
    fontSize: "1.8rem",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.6rem",
    },
  },
  pointDesc: {
    fontFamily: "ヒラギノ丸ゴ ProN W6",
    fontSize: "1.4rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  },
}))

const MenuPage = () => {
  const classes = useStyles()
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "star_background.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <Layout>
      <Seo title="MENU/メニュー" />
      <Typography variant="body2" align="right" className={classes.hosokuTxt}>
        <u>※全て税込価格です。</u>
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.titleContainer1}
        spacing={1}
      >
        <Grid item>
          <Typography variant="h1" align="center">
            <OutlinedGreyTxt className={classes.titleInlineLeft1} color="black">
              ヘア
            </OutlinedGreyTxt>
            <OutlinedGreyTxt
              className={classes.titleInlineRight1}
              color="deeppink"
            >
              カラー
            </OutlinedGreyTxt>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h1">
            <OutlineBlack>MENU</OutlineBlack>
          </Typography>
        </Grid>
      </Grid>
      {isMobile ? (
        <StaticImage
          src="../images/sp_menu1.png"
          alt="menu1"
          placeholder="blurred"
          layout="fullWidth"
        />
      ) : (
        <StaticImage
          src="../images/pc_menu1.png"
          alt="menu1"
          placeholder="blurred"
          layout="fullWidth"
        />
      )}
      <div className={classes.blackContainer}>
        <div className={classes.whiteContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.titleContainer2}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h1" align="center">
                <OutlinedGreyTxt className={classes.titleInlineLeft2}>
                  デザイン
                </OutlinedGreyTxt>
                <OutlinedGreyTxt className={classes.titleInlineRight2}>
                  カラー
                </OutlinedGreyTxt>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h1">
                <OutlineYellow>MENU</OutlineYellow>
              </Typography>
            </Grid>
          </Grid>
          {isMobile ? (
            <StaticImage
              src="../images/sp_menu2.png"
              alt="menu2"
              placeholder="blurred"
              layout="fullWidth"
            />
          ) : (
            <StaticImage
              src="../images/pc_menu2.png"
              alt="menu2"
              placeholder="blurred"
              layout="fullWidth"
            />
          )}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.titleContainer2}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h1" align="center">
                <OutlinedGreyTxt className={classes.titleInlineLeft3}>
                  エクストラ
                </OutlinedGreyTxt>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h1">
                <OutlinedBlue>OPTION</OutlinedBlue>
              </Typography>
            </Grid>
          </Grid>
          {isMobile ? (
            <StaticImage
              src="../images/sp_menu3.png"
              alt="menu3"
              placeholder="blurred"
              layout="fullWidth"
            />
          ) : (
            <StaticImage
              src="../images/pc_menu3.png"
              alt="menu3"
              placeholder="blurred"
              layout="fullWidth"
            />
          )}
        </div>
        <BackgroundImage
          fluid={imageData}
          style={{
            backgroundRepeat: "repeat",
            backgroundSize: "100%",
            padding: "4rem",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1rem",
            }}
          >
            <Typography variant="h4" className={classes.pointTitle}>
              ★前日割で<span style={{ color: "red" }}>20</span>%オフ★
            </Typography>
            <Typography variant="body1" className={classes.pointDesc}>
              <span style={{ color: "red", textDecoration: "underline" }}>
                赤字
              </span>
              の価格は、<span style={{ color: "blue" }}>前日割</span>(
              <u>
                前日<span style={{ color: "red" }}>19</span>時まで
              </u>
              のご予約)にてとってもお得！ぜひご利用ください。
            </Typography>
            <hr />
            <Typography variant="h4" className={classes.pointTitle}>
              ★組み合わせて全てを実現★
            </Typography>
            <Typography variant="body1" className={classes.pointDesc}>
              <u>
                ヘア<span style={{ color: "deeppink" }}>カラー</span>MENU
              </u>
              (おしゃれ染め等)、
              <u>
                <span style={{ color: "red" }}>デザイン</span>
                <span style={{ color: "blue" }}>カラー</span>MENU
              </u>
              (インナーカラー等)、 更に
              <u>
                <span style={{ color: "darkorange" }}>エクストラ</span>
                <span style={{ color: "purple" }}>OPTION</span>
              </u>
              を組合せ理想に限りなく近づけます。
            </Typography>
            <hr />
            <Typography variant="h4" className={classes.pointTitle}>
              ★ギャラリーと連動★
            </Typography>
            <Typography variant="body1" className={classes.pointDesc}>
              インスタの<u>魔術カルテ</u>をご覧下さい♪ 記載の
              <span style={{ color: "red" }}>PRICE</span>
              は、この[MENU/価格表]と
              <span style={{ color: "red" }}>完全連動</span>
              ！「何をどうして何がおいくらか」を分かり易く！
            </Typography>
            <hr />
            <Typography variant="h4" className={classes.pointTitle}>
              ★更に<span style={{ color: "red" }}>おトク</span>に★
            </Typography>
            <Typography variant="body1" className={classes.pointDesc}>
              <span style={{ color: "blue" }}>[セルフ]</span>
              をご希望→[ご自身ブロー]で更に
              <span style={{ color: "red" }}>お安く</span>♪
              スタイリング剤も豊富にご用意！
            </Typography>
          </div>
        </BackgroundImage>
        <div style={{ padding: "2rem", backgroundColor: "white" }}>
          <Typography
            variant="body2"
            align="right"
            className={classes.hosokuTxt}
          >
            <u>※価格はシャンプー料金込みとなっております。</u>
          </Typography>
          <Typography
            variant="body2"
            align="right"
            className={classes.hosokuTxt}
          >
            <u>
              ※ブロー料金につきましては、 髪の長さ[S/M 550]、
              <br />
              [L/LL 1,100]となります。
            </u>
          </Typography>
        </div>
      </div>
    </Layout>
  )
}

export default MenuPage
